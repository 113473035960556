@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&family=Inconsolata:wght@500&family=Space+Mono&display=swap');

.title {
    padding-top: 50px;
    padding-bottom: 20px;
    font-family: 'DM Mono', monospace;
    font-size: 50px;
}

.research {
    width: 90%;
}

.header {
    font-size: 15px;
    display: flex;
    font-family: 'DM Mono', monospace;
    align-items: center;
    color: black;
    background-color: #bacabe;
    padding-left: 5px;
    padding-right: 5px;
    width: fit-content;
    height: 40px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    column-gap: 15px;
    row-gap: 30px;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
}

.desc {
    background-color: #FFFCF4;
    border: 1px solid #B1C9EB;
    padding: 10px;
}
.researchCard {
    border: 3px solid #bacabe;
    background-size: 35px 35px;
    background-image:
    linear-gradient(to right, #B1C9EB 1px, transparent 1px),
    linear-gradient(to bottom, #B1C9EB 1px, transparent 1px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    width: 90%;
    height: fit-content;
    padding: 20px;
    gap: 10px;
    box-shadow: 2px 3px #bacabe;
}