@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&family=Inconsolata:wght@500&family=Space+Mono&display=swap');

.container {
    display: flex;
    width: 100%;
    height: 5vh;
    font-family: 'DM Mono', monospace;
    align-items: center;
    justify-content:space-evenly;
    background-color: #e7cac2;

    position: fixed;
    top:0;
    z-index: 1000;
}

.container >:hover {
    cursor: pointer;
}

@media only screen and (max-width: 768px) {
    .container {
       visibility: hidden;
    }
}