
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=DM+Sans:ital,opsz,wght@0,9..40,200;1,9..40,200&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&family=Inconsolata:wght@500&family=Space+Mono&display=swap');

.realContainer {
    width:100%;
    height: 100vh;
    background-color: #FFFCF4;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-size: 40px 40px;
    background-image:
    linear-gradient(to right, #B1C9EB 1px, transparent 1px),
    linear-gradient(to bottom, #B1C9EB 1px, transparent 1px);
}

.downButton {
    background-color: #e7cac2;
    color: #323232;
    text-transform: lowercase;
    height: 40px;
    width: 40px;
    border-radius: 100px;
}

.container {
    padding-top: 100px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.wordContainer {
    width: 60%;
    display: flex;
    background-color: #FFFCF4;
    box-shadow: 9px 9px #e7cac2;
    border: 3px solid #e7cac2;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.imageContainer {
    width: 25%;
}

.title {
    padding-top: 50px;
    font-family: 'DM Mono', monospace;
    font-size: 4rem;
}

.intro {
    width: 60%;
}

.downButton {
    transform: scale(1.5);
}

.profPic {
    width: 100%;
    max-width: 40vh;
    height:auto;
    border: 1px solid #323232;
    border-radius: 100px 0px 0px 0px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    width: 100%;
}

p {
    font-size: 20px;
    line-height: 1.5;
    color: #323232;
}

@media only screen and (max-width: 768px) {
    .realContainer {
        height: auto;
        padding: 20px;
        width: 100%;
    }

    .container {
        flex-direction: column;
        padding-top: 50px; 
    }

    .wordContainer {
        gap: 20px; 
        box-shadow: 0px 0px #e7cac2; 
        width: 90%;
    }

    .imageContainer {
        width: 60%;
        margin-top: 20px;
    }

    .title {
        font-size: 2.5rem;
        padding-top: 20px;
    }


    .profPic {
        max-width: 30vh; 
        border-radius: 50px 0px 0px 0px; 
    }

    .buttons {
        flex-direction: column;
        gap: 15px;
        width: 100%;
        padding: 10px; 
        align-items: center;
    }

    .downButton {
        visibility: hidden;
    }

    p {
        font-size: 16px;
    }
}

@media only screen and (max-width: 480px) {
    .title {
        font-size: 2rem;
    }

    .intro {
        font-size: 14px;
        line-height: 1.3; 
    }

    .buttons {
        gap: 10px;
    }
}
