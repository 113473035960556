@import url('https://fonts.googleapis.com/css2?family=DM+Mono:wght@500&family=Inconsolata:wght@500&family=Space+Mono&display=swap');

.title {
    padding-top: 50px;
    padding-bottom: 20px;
    font-family: 'DM Mono', monospace;
    font-size: 50px;
}

.socials {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 10vh;
    gap: 20px;
}